<template>
  <div class="taskDetails">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">任务详情</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div>
        <ImgDecypt class="taskImage" :src="item" v-for="item in taskDetail.image" :key="item" />
      </div>
      <!-- <div class="taskTip">{{ taskDetail.desc }}</div> -->
      <div v-if="showLeaderboard">
        <svg-icon class="leaderboardTitle" iconClass="revenueList"></svg-icon>
        <div class="leaderboardItem" v-for="(i, index) in taskDetail.rank" :key="index">
          <div @click="openVideo(i)" class="workCoverBox">
            <ImgDecypt class="workCover" :src="i.cover" />
            <div class="rankBox">
              <svg-icon class="rankIcon" :iconClass="getRank(index)"></svg-icon>
              <div class="rankText">
                <span>{{ index + 1 }}</span>
                <span>TOP</span>
              </div>
            </div>
          </div>
          <div class="infoBox">
            <div class="workDesc">{{ i.title }}</div>
            <div class="userName">作者：{{ i.name }}</div>
            <div class="rankData">
              <div class="totalNumBox">
                <span>购买人数：</span>
                <span class="totalNum">{{ i.purchased | watchCountW }}人</span>
              </div>
              <div class="moneyBox">
                <svg-icon class="rankGold" iconClass="rankGold"></svg-icon>
                <div>{{ i.bonus || 0 }}元</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox"></div>
    </div>
    <div class="btn" @click="doTask" v-if="!taskDetail.isclosed">立即参与</div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { getVidInfo } from '@/api/video';
export default {
  name: 'taskDetails',
  components: {
    ImgDecypt,
  },
  data() {
    return {
      taskDetail: '',
      showLeaderboard: false,
    };
  },
  created() {
    let dataStr = this.$route.query.data;
    let decodeData = window.atob(dataStr);
    this.taskDetail = JSON.parse(decodeURI(window.decodeURI(decodeData)));
    if (this.taskDetail.rank != null && this.taskDetail.isclosed) {
      this.showLeaderboard = true;
    }
  },
  methods: {
    doTask() {
      if (this.taskDetail.type == 1 && this.taskDetail.status == 1) {
        this.taskSing(this.taskDetail);
      } else if (this.taskDetail.type == 2 || this.taskDetail.type == 3) {
        this.$router.push({
          path: '/uploadVIdeo',
          query: {
            tId: this.taskDetail.id,
          },
        });
      }
    },
    getRank(index) {
      let rankStr;
      switch (index) {
        case 0:
          rankStr = 'rank01';
          break;
        case 1:
          rankStr = 'rank02';
          break;
        case 2:
          rankStr = 'rank03';
          break;
        default:
          rankStr = 'rank';
          break;
      }
      return rankStr;
    },
    async openVideo(item) {
      if (item.newsType == 'MOVIE') {
        let routeObj = {
          path: '/horizontalVideo',
          query: {
            id: item.vid,
          },
        };
        this.$router.push(routeObj);
        return;
      }
      let req = {
        videoID: item.vid,
      };
      let ret = await this.$Api(getVidInfo, req);
      this.$store.commit('video/SET_VIDEOLIST', {
        list: [ret.data],
      });

      let routeData = {
        path: '/communityVideo',
        query: {},
      };
      this.$router.push(routeData);
    },
  },
};
</script>

<style lang="scss" scoped>
.taskDetails {
  height: 100%;
  .navBar {
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 0.7px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 116px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .taskImage {
      margin: 0 0 10px;
      /deep/ .laz_com {
        background-color: unset;
      }
      /deep/ img {
        width: 100%;
        height: auto !important;
        object-fit: cover !important;
      }
    }

    .taskTip {
      margin: 0 16px 30px;
      font-size: 16px;
      line-height: 28px;
      white-space: pre-wrap;
    }

    .leaderboardTitle {
      width: 57px;
      height: 18px;
      margin: 0px 12px 10px;
    }

    .leaderboardItem {
      height: 136px;
      margin: 0px 12px 10px;
      padding: 0 7px 0 10px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff;
      display: flex;
      align-items: center;
      .workCoverBox {
        position: relative;
        .workCover {
          width: 98px;
          height: 120px;
          margin-right: 10px;
          border-radius: 4px;
          overflow: hidden;
        }
        .rankBox {
          position: absolute;
          left: 8px;
          top: 0;
          .rankIcon {
            width: 35px;
            height: 36px;
          }

          .rankText {
            width: 100%;
            color: #fff;
            font-size: 12px;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .infoBox {
        width: 269px;
        height: 100%;

        .workDesc {
          font-size: 14px;
          margin: 18px 0px 3px;
        }

        .userName {
          font-size: 12px;
          color: rgb(102, 102, 102);
        }

        .rankData {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;

          .totalNumBox {
            font-size: 14px;
            color: #9a9a9a;

            .totalNum {
              font-size: 16px;
              color: rgb(246, 106, 72);
            }
          }

          .moneyBox {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: rgb(255, 125, 85);

            .rankGold {
              width: 20px;
              height: 20px;
              margin-right: 5.2px;
            }
          }
        }
      }
    }

    .bottomBox {
      height: 120px;
    }
  }

  .btn {
    width: 228px;
    height: 44px;
    line-height: 44px;
    border-radius: 44px;
    text-align: center;
    color: #fff;
    background-color: rgb(255, 103, 143);
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
}
</style>
